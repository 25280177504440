<template >
  <div id="CreateStore" :style="marginTopJudge()">
    <div class="dataRow">
      <div class="RowTitle"> <span class="starMark">★ </span>我的商店名称 : </div>
      <el-input
        class="inputBlock"
        v-model="formData.storeName"
        placeholder="请输入商店名称"
        :disabled="isModifyMode"
      ></el-input>
      <div class="commentBlock"> ( 注：只能设置一次 ) </div>
    </div>
    <div class="dataRow">
      <div class="RowTitle"> <span class="starMark">★ </span>商店简介 : </div>
      <el-input
        class="inputBlock"
        :rows="4"
        type="textarea"
        v-model="formData.storeIntro"
        placeholder="请输入商店简介"
      ></el-input>
      <div class="commentBlock">
        ( 注：请按实际作简介，不要使用违法违规的词 语。简介内容需要管理员审核通过才能显示 )
      </div>
    </div>
    <div class="dataRow">
      <div class="RowTitle NotAlignCenterTitle"> <span class="starMark">★ </span>擅长的彩种 : </div>
      <div class="inputBlock">
        <template v-for="(it, index) in currentSelectShowLength">
          <el-cascader
            v-model="formData.goodAtLot[index].bindValue"
            :show-all-levels="false"
            :options="lotteryChooseOption"
            :key="index"
          ></el-cascader>
        </template>

        <div class="simpleRow" v-if="currentSelectShowLength < GOOD_AT_LOT_NUM">
          <div class="plusBtn" @click="plusBtnHandler">
            <i class="el-icon-plus"></i>
          </div>
          <div class="commentBlock" style="width: auto; margin-left: 5px"> ( 新增彩种 ) </div>
        </div>
      </div>
      <div class="commentBlock NotAlignCenterComment"> ( 注：最多可设置五个 ) </div>
    </div>

    <el-button type="primary" class="submit" @click="submitHandler">送出</el-button>
  </div>
</template>
<script>
const GOOD_AT_LOT_NUM = 5
export default {
  name: 'CreateStore',
  props: {
    shopInfo: {
      type: Object,
    },
  },
  data() {
    return {
      formData: {
        storeName: '',
        storeIntro: '',
        goodAtLot: new Array(GOOD_AT_LOT_NUM).fill().map((it) => ({ bindValue: '' })),
      },
      allLotteries: [],
      isModifyMode: false,
      currentSelectShowLength: 0,
    }
  },
  async created() {
    this.$store.commit('setLoading', true)
    await this.$store.dispatch('shop/getLotteryTypes').catch(this.$autoCatch)
    await this.$store
      .dispatch('shop/getLotteries')
      .then((res) => {
        const filterLotType = res.data.reduce((sum, it, index) => {
          if (!sum.hasOwnProperty(it.type)) {
            sum[it.type] = {
              label: it.type,
              value: null,
              children: [],
            }
          }
          if (it.predict_state === '1') {
            sum[it.type].children.push({
              label: it.lotteryname,
              value: it.lotteryname,
            })
          }
          return sum
        }, {})
        for (const it in filterLotType) {
          this.allLotteries.push(filterLotType[it])
        }
      })
      .catch(this.$autoCatch)
    this.$store.commit('setLoading', false)
  },
  computed: {
    // 給template吃得變數
    GOOD_AT_LOT_NUM() {
      return GOOD_AT_LOT_NUM
    },
    lotteryChooseOption() {
      if (this.allLotteries.length === 0) {
        return []
      } else {
        const options = this.allLotteries
          .map((it) => it)
          .sort((a, b) => {
            return (
              this.$store.state.shop.lottery_types_sort.indexOf(a.label) -
              this.$store.state.shop.lottery_types_sort.indexOf(b.label)
            )
          })
        options.push({
          value: 'empty',
          label: '无',
        })
        return options
      }
    },
  },
  watch: {
    shopInfo: {
      handler: function (newValue, oldValue) {
        if (Object.keys(this.shopInfo).length !== 0) {
          this.initModifyMode(
            this.shopInfo.shopname,
            this.shopInfo.introduction,
            this.shopInfo.preferred_type
          )
        }
      },
      immediate: true,
    },
  },
  methods: {
    initCurrentSelectShowLength() {
      this.currentSelectShowLength = this.formData.goodAtLot.reduce((sum, it) => {
        if (it.bindValue !== '') {
          return sum + 1
        } else {
          return sum
        }
      }, 0)
    },
    initModifyMode(shopname, introduction, preferred_type) {
      this.isModifyMode = true
      this.formData.storeName = shopname
      this.formData.storeIntro = introduction
      try {
        const parsePreferred_type = JSON.parse(preferred_type)
        this.formData.goodAtLot = parsePreferred_type
        this.formData.goodAtLot = [
          ...parsePreferred_type,
          ...Array.from({ length: GOOD_AT_LOT_NUM - parsePreferred_type.length }, () => ({
            bindValue: '',
          })),
        ]
        this.initCurrentSelectShowLength()
      } catch (err) {}
    },
    plusBtnHandler() {
      this.currentSelectShowLength++
    },
    async submitHandler() {
      const { storeName, storeIntro, goodAtLot } = this.formData
      if (storeName === '') {
        this.$MSG.info('商店名称 尚未输入')
      } else if (storeIntro === '') {
        this.$MSG.info('商店简介 尚未输入')
      } else {
        const goodAtLotList = goodAtLot
          .map((it) => it.bindValue[1])
          .filter((it) => typeof it !== 'undefined')
        // 檢查是否重複
        const hasRepeat =
          goodAtLotList.filter((it, index) => goodAtLotList.indexOf(it) !== index && it !== '')
            .length !== 0
        if (hasRepeat) {
          this.$MSG.info('擅长的彩种 选择项目出现重复')
          return
        }

        const preferred_type = JSON.stringify(
          goodAtLot
            .filter((it) => typeof it !== 'undefined')
            .filter((it) => it.bindValue.length != 1)
            .filter((it) => it.bindValue != '')
        )

        const postObj = {
          name: storeName,
          introduction: storeIntro,
          preferred_type: preferred_type,
        }

        this.$store.commit('setLoading', true)
        if (this.isModifyMode) {
          postObj.id = this.shopInfo.id
          // @Modify
          await this.$store
            .dispatch('shop/modifyMyShop', postObj)
            .then((res) => {
              this.$MSG.success('修改成功')
            })
            .catch(this.$autoCatch)
            .finally(() => {
              this.$emit('checkCreateMode')
              this.$store.commit('setLoading', false)
            })
        } else {
          // @Create
          await this.$store
            .dispatch('shop/createMyShop', postObj)
            .then((res) => {
              this.$MSG.success('新建商店成功')
              this.$emit('checkCreateMode')
              this.$router.replace({
                name: 'MemberStoreStoreIntro',
              })
            })
            .catch(this.$autoCatch)
            .finally(() => {
              this.$store.commit('setLoading', false)
            })
        }
      }
    },
    marginTopJudge() {
      if (this.isModifyMode) {
        return 'margin-top:20px;'
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/views/Member/Store/Store.scss';
#CreateStore {
  .simpleRow {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .el-select {
    width: 100%;
  }
  .el-input,
  .el-textarea {
    padding: 10px;
    font-weight: normal;
    @media screen and (max-width: 501px) {
      margin-top: -10px;
    }
  }
  .el-select {
    padding: 6px;
  }
  .el-cascader {
    padding: 6px;
  }
  .dataRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font: normal normal bold 1rem 'Microsoft Yahei';
    text-align: left;
    line-height: 35px;
    border-bottom: 1.5px $borderLineColor solid;
    padding: 18px 0;
    &:first-child {
      padding-top: 0;
      margin-top: -5px;
    }
    .RowTitle {
      width: 150px;
      margin-top: -4px;
      font-size: 1rem;
      .starMark {
        color: #ff5757;
        font-size: 1.2rem;
      }
    }
    .NotAlignCenterTitle,
    .NotAlignCenterComment {
      align-self: baseline;
      margin-top: 6px;
    }
    .commentBlock {
      color: #87b3f9;
      font-size: 0.812rem;
      line-height: 1rem;
      width: 280px;
      padding: 7px 0;
    }

    .inputBlock {
      width: 290px;
      margin-right: 10px;
    }
    .plusBtn {
      background: #d7d7d7 0% 0% no-repeat padding-box;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;
      i {
        font-weight: bold;
        color: white;
        font-size: 22px;
      }
    }
  }
  .submit {
    margin-top: 20px;
  }
}
</style>
